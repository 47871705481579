import React, {Component} from 'react';
import Slider from "react-slick";
import {
    Link
} from "react-router-dom";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import ReactDOM from 'react-dom';
import Menu from "../Menu";
import Preloader from "../Preloader";

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

export class Home extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: '',
            events: [],
            service: [],
            dateTime: '',
            activeBlock: '',
            openChangePoint: false,
            isLoad: false,
            auth: false,
            countNextRecord: 0,
            activeCompany: [],
            record: [],
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }


    updateData = (value) => {
        this.setState({activeBlock: value})
    }

    updateService = (value) => {
        sessionStorage.setItem("serviceActive", JSON.stringify(value));
        this.setState({service: value, activeBlock: 'menu'})
    }

    updateStaff = (value) => {
        sessionStorage.setItem("staffActive", JSON.stringify(value));
        this.setState({staff: value, activeBlock: 'menu'})
    }

    updateDateTime = (value) => {
        sessionStorage.setItem("dateTime", JSON.stringify(value));
        this.setState({dateTime: value, activeBlock: 'menu'})
    }

    updateCompany = (value) => {
        sessionStorage.setItem("companyActive", JSON.stringify(value));
        this.setState({activeCompany: value, activeBlock: 'menu'})
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        let urlAPI = GlobalVariable.host() + '/mobile/client/home';
        const data = {};
        axios({
            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    activeCompany: result.data,
                    datework: result.data.days,
                    countNextRecord: result.data.countNextRecord,
                    isLoad: true,
                    auth: true
                })
            }).catch(error => {
            this.clientInfoNoAuth()
            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })


        let urlAPI2 = GlobalVariable.staffBack() + '/getEvents';
        const data2 = {};
        axios({
            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data2,
            withCredentials: true,

        })
            .then(result => { // then print response status
                this.setState({
                    events: result.data,
                })
            }).catch(error => {
            this.clientInfoNoAuth()
            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })

        let urlAPI3 = GlobalVariable.host() + '/widget/staff/v1/profile/my-record/next';
        var date = new Date();

        function leadingZero(token) {
            return ("0" + token).slice(-2)
        }

        var year = date.getFullYear(),
            month = leadingZero(date.getMonth() + 1), // подсчет начинается с 0, поэтому увеличим на 1
            day = leadingZero(date.getDate()),
            hours = leadingZero(date.getHours()),
            minutes = leadingZero(date.getMinutes()),
            seconds = leadingZero(date.getSeconds())


        var result = `${year}-${month}-${day} ${hours}:${minutes}`;
        console.log(result);
        const data3 = {
            'dateTime': result,
            'limit': 2,
        };
        axios({
            url: urlAPI3,
            headers: {'Content-Type': 'application/json'},
            method: "post",
            data: data3,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                console.log(result.data)
                this.setState({
                    record: result.data,
                })
            }).catch(error => {
            this.clientInfoNoAuth()
            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }


    clientInfoNoAuth() {
        document.addEventListener('click', this.handleClickOutside, true);
        let urlAPI = GlobalVariable.host() + '/open/client/home';
        const data = {};
        axios({
            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    activeCompany: result.data,
                    datework: result.data.days,
                    isLoad: true,
                    auth: false
                })
            }).catch(error => {
            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    changeStatus() {
        this.setState({openChangePoint: true})
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        // console.log(this.state.openChangePoint)
        // console.log(event.target.className)
        if (this.state.openChangePoint === true) {
            if (event.target.className === "changePointBlock") {
                this.setState({openChangePoint: false})
            }
        }
    }

    renderChangePoint() {
        if (this.state.openChangePoint === true) {
            return (
                <div className={'changePointBlock'}>
                    <div className={'changePoint'}>
                        <div className={'block-address mt-3 mb-3'}>

                            {this.state.activeCompany.city}, {this.state.activeCompany.address}
                        </div>
                        <div className={'block-work mb-3'}>
                            <div>Режим работы:</div>
                            {this.state.datework}

                        </div>
                        <div className={'block-phone mb-3'}>
                            <div>Телефон:</div>
                            <div>8 800 600-65-16</div>
                        </div>
                        <Link to={'/country'}>
                            <div className={'btn-active mt-3 mb-3'}>
                                Сменить филиал
                            </div>
                        </Link>
                    </div>
                </div>
            )
        }

    }

    renderBlockBalls() {
        console.log(this.state.auth)
        if (this.state.auth === true) {
            return (
                <div className={'title'}>{this.state.activeCompany.ball} баллов</div>
            )
        } else {
            return (
                <div className={'title'}>Мои баллы</div>
            )
        }
    }

    renderBlockName() {
        if (this.state.auth === true) {
            return (
                <div>
                    <div className='white-text txt'>Приветствуем в Чио Чио,</div>
                    <div className='white-text txt'>{this.state.activeCompany.nameClient}</div>
                </div>
            )
        }
    }

    renderCountRecord() {
        return (<div className={'title'}>График работы</div>)
    }

    renderRecord() {
        if (!this.state.activeCompany.recordOnline) {
            return (
                <div className={'body_block_record_and_history'}>
                    {/*<h2 className={'title'}>Записаться</h2>*/}
                    <div className={'block_record_and_history d-flex'}>
                        <div className={'onlineRecord'}>
                            <Link to={'/date'}>
                                <div className={'btn_block'}>
                                    <div className={'image'}>
                                        <img src={'/img/icon-staff.png'} alt=''/>
                                    </div>
                                    <div className={'title'}>Мои записи</div>
                                </div>
                            </Link>
                            <Link to={'/point'}>
                                <div className={'btn_block'}>
                                    <div className={'image'}>
                                        <img src={'/img/icon-service.png'} alt=''/>
                                    </div>
                                    <div className={'title'}>Статистика смены</div>
                                </div>
                            </Link>
                            {/*<Link to={'/point'}>*/}
                            {/*    <div className={'btn_block'}>*/}
                            {/*        <div className={'image'}>*/}
                            {/*            <img src={'/img/icon-time.png'} alt=''/>*/}
                            {/*        </div>*/}
                            {/*        <div className={'title'}>По времени</div>*/}
                            {/*    </div>*/}
                            {/*</Link>*/}
                        </div>

                        <div className={'history'}>
                            <Link to={'/date-grafics'}>
                                <div className={'btn_block'}>
                                    <div className={'image'}>
                                        <img src={'/img/icon4.png'} alt=''/>
                                    </div>

                                    {this.renderCountRecord()}

                                </div>
                            </Link>

                        </div>

                    </div>
                </div>
            )
        } else {
            return (
                <div className={'body_block_record_and_history'}>
                    <h2 className={'title'}>Записаться</h2>
                    <div className={'block_record_and_history d-flex'}>
                        <div className={'onlineRecord'}>
                            <div className={'no-record'}>
                                <div className={'image'}><img src={'/img/no-record.png'} alt=''/></div>
                                <div className={'text'}>Онлайн запись не доступна</div>
                            </div>

                        </div>
                        <div className={'history'}>
                            <div className={'btn_block'}>
                                <div className={'image'}>
                                    <img src={'/img/icon4.png'} alt=''/>
                                </div>
                                {this.renderCountRecord()}
                            </div>
                            <div className={'btn_block'}>
                                <div className={'image'}><img src={'/img/ball-icon.png'} alt=''/></div>
                                {this.renderBlockBalls()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderBlockActivePoint() {
        if (this.state.auth === true) {
            return (
                <div className='mx-w-content choose-town p-sides'>
                    <div className='p-3'>
                        <img className='ic-town' src={'/img/point.png'} alt=''/>
                        {this.state.activeCompany.city}, {this.state.activeCompany.address}
                        <img className='ic-arrow' src={'/img/arrowRight2.png'} alt=''/>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='mx-w-content choose-town p-sides'>
                    <div className='p-3'>
                        <img className='ic-town' src={'/img/point.png'} alt=''/>
                        <span className='txt-middle'>Выберите город</span>
                        <img className='ic-arrow' src={'/img/arrowRight2.png'} alt=''/>
                    </div>
                </div>
            )
        }
    }

    renderNoRecords() {
        if (this.state.record.length == 0) {
            return (
                <div>
                    <div className='chio-card-subheader ml-3 mr-3 pl-3 pr-3 pt-2 d-flex justify-content-between '>
                        <span className='text-white txt-middle'></span>
                        <span className='text-white txt-middle'></span>
                    </div>
                    <div className='chio-card ml-3 mr-3 mb-2 pl-3 pr-3 pt-2 pb-2'>
                        <div className='d-flex'>
                            <div className='d-flex flex-column w-100'>
                                <div className='txt-middle bold mt-3 mb-3 text-center'>Ближайших записей не найдено</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        if (!this.state.isLoad) {
            return (
                <Preloader></Preloader>
            )
        } else {
            return (
                <div className='main home'>

                    {/*Заголовок home*/}
                    <header>
                        {/*Верхняя часть header*/}
                        <div className='p-sides mx-w-content header-wrap'>
                            <div className='d-flex align-items-center'>
                                <Link to='/profile'>
                                    <img src='/img/home/ico-profile.svg' alt=''
                                         className='img-profile mr-3'/>
                                </Link>
                                <div className='white-text txt-middle ml-0'>
                                    Приветствуем в Чио Чио,<br/>
                                    <Link to='/profile'
                                          className='white-text txt-middle bold'>{this.state.activeCompany.nameClient}</Link>
                                </div>
                            </div>
                            <Link to={'/notifications'}>
                                <img src='/img/home/ico-notifications.svg' alt=''
                                     className='ico-notifications'/>
                            </Link>
                        </div>
                    </header>

                    <div className='content mx-w-content'>

                        {/*Ближайшие записи*/}
                        <div className='ml-3 mr-3 mt-4 d-flex justify-content-between'>
                            <div className='p-3 p-sides chio-mark txt-large'>
                                Ближайшие&nbsp;записи
                            </div>
                            <Link to='/records' className='p-3 txt-large chio-mark-back'>
                                Все
                            </Link>
                        </div>

                        {this.renderNoRecords()}
                        {/*Запись*/}
                        {this.state.record.map((itemRecord) => (
                            <div>
                                <div
                                    className='chio-card-subheader ml-3 mr-3 pl-3 pr-3 pt-2 d-flex justify-content-between '>
                                    <span className='text-white txt-middle'>{itemRecord.date}</span>
                                    <span className='text-white txt-middle'>{itemRecord.time}</span>
                                </div>
                                <div className='chio-card ml-3 mr-3 mb-2 pl-3 pr-3 pt-2 pb-2'>
                                    <div className='d-flex'>
                                        {/*<img src='/img/home/ico-profile.svg' alt=''*/}
                                        {/*     className='mr-3'/>*/}
                                        <div className='d-flex flex-column w-100'>
                                            <div className='d-flex justify-content-between'>
                                                <div className='txt-large mb-2'>{itemRecord.client}</div>
                                                <div className='txt-middle light mt-1'>

                                                </div>
                                            </div>
                                            <div className='txt-middle'>{itemRecord.service}</div>
                                            <div className='txt-middle bold'>{itemRecord.paid}р</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}
                        {/*Кнопка*/}
                        <Link to='/date-schedule'>
                            <div className="ml-3 mr-3 mt-4 mb-4">
                                <div className='text-center bg-darkch text-white chio-card p-3 txt-large'>
                                    <img className='mr-2' src='/img/home/ico-clock.svg' alt=''/>
                                    График работы
                                </div>
                            </div>
                        </Link>

                        {/*Ближайшие события*/}
                        <div className='ml-3 mr-3 d-flex justify-content-between'>
                            <div className='p-3 p-sides chio-mark txt-large'>
                                Ближайшие&nbsp;события
                            </div>
                            <Link to='/events' className='p-3 txt-large chio-mark-back'>
                                Все
                            </Link>
                        </div>
                        {/*Событие*/}
                        <div className='ml-3 mr-3'>
                            <Slider {...settings}>

                                <Link to={'/event/4'}>
                                    <div
                                        className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between'>
                                        <span className='text-white txt-middle'>13.09.2024</span>
                                        <span className='text-white txt-middle'></span>
                                    </div>
                                    <div className='chio-card mb-2'>
                                        <img className='card-img-wh' alt=''
                                             src='/img/events/event12.jpg'/>
                                        <div className='p-3 txt-middle bold'>
                                            Всероссийский слет мастеров г. Казань
                                        </div>
                                    </div>
                                </Link>
                                <Link to={'/event/1'}>
                                    <div
                                        className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between'>
                                        <span className='text-white txt-middle'>13.09.2024</span>
                                        <span className='text-white txt-middle'></span>
                                    </div>
                                    <div className='chio-card mb-2'>
                                        <img className='card-img-wh' alt=''
                                             src='/img/events/event11.jpg'/>
                                        <div className='p-3 txt-middle bold'>
                                            С Днем Парикмахера! Промокоды на скидки.
                                        </div>
                                    </div>
                                </Link>
                                <Link to={'/event/2'}>
                                    <div
                                        className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between'>
                                        <span className='text-white txt-middle'>10.09.2024</span>
                                        <span className='text-white txt-middle'></span>
                                    </div>
                                    <div className='chio-card mb-2'>
                                        <img className='card-img-wh' alt=''
                                             src='/img/events/img-event1.jpg'/>
                                        <div className='p-3 txt-middle bold'>
                                            Как общаться с посетителем, чтобы он стал твоим
                                            постоянным клиентом?

                                        </div>
                                    </div>
                                </Link>
                                <Link to={'/event/3'}>
                                    <div
                                        className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between'>
                                        <span className='text-white txt-middle'>07.09.2024</span>
                                        <span className='text-white txt-middle'></span>
                                    </div>
                                    <div className='chio-card mb-2'>
                                        <img className='card-img-wh' alt=''
                                             src='/img/events/img-event1.jpg'/>
                                        <div className='p-3 txt-middle bold'>
                                            Как подобрать стрижку под тип внешности клиента?
                                        </div>
                                    </div>
                                </Link>


                            </Slider>
                        </div>


                        {/*Кнопки*/}
                        <div className="ml-3 mr-3 mt-5 mb-0 d-flex">
                            <Link to='/events'
                                  className='text-center chio-card p-3 txt-large w-50 mr-1 d-flex justify-content-center align-items-center'>
                                <img className='mr-2' src='/img/home/ico-events.svg' alt=''/>
                                События
                            </Link>
                            <Link to='/knowledge-base'
                                  className='text-center chio-card p-3 txt-large w-50 ml-1 d-flex justify-content-center align-items-center'>
                                <img className='mr-2' src='/img/home/ico-lamp.svg' alt=''/>
                                Знания
                            </Link>
                        </div>
                        {/*<div className="ml-3 mr-3 mt-2 mb-4 d-flex">*/}
                        {/*    <Link to='/analytics'*/}
                        {/*          className='w-100 text-center bg-red text-white chio-card p-3 txt-large'>*/}
                        {/*        <img className='mr-2' src='/img/home/ico-analytics.svg' alt=''/>*/}
                        {/*        Аналитика*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                    </div>
                    <Menu></Menu>
                </div>
            )
        }
    }
}

export default Home;